import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e1b0b0e = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _745416d6 = () => interopDefault(import('../pages/b2b/index.vue' /* webpackChunkName: "pages/b2b/index" */))
const _7f9a6a37 = () => interopDefault(import('../pages/cms/blog.vue' /* webpackChunkName: "pages/cms/blog" */))
const _ac46d016 = () => interopDefault(import('../pages/categories/curtains.vue' /* webpackChunkName: "pages/categories/curtains" */))
const _47fd6d85 = () => interopDefault(import('../pages/surcharge.vue' /* webpackChunkName: "pages/surcharge" */))
const _2fca04ec = () => interopDefault(import('../pages/categories/relax.vue' /* webpackChunkName: "pages/categories/relax" */))
const _7b486698 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _fba69e58 = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _a7332d26 = () => interopDefault(import('../pages/auth/login/seller.vue' /* webpackChunkName: "pages/auth/login/seller" */))
const _accf6876 = () => interopDefault(import('../pages/static/montage.vue' /* webpackChunkName: "pages/static/montage" */))
const _6ef99b2e = () => interopDefault(import('../pages/print.vue' /* webpackChunkName: "pages/print" */))
const _037ff553 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _5ea4d0b8 = () => interopDefault(import('../pages/categories/pleated-blinds.vue' /* webpackChunkName: "pages/categories/pleated-blinds" */))
const _de2d63a0 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _b9a31e9a = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _e027ce1a = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _625e4594 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _6c5d824a = () => interopDefault(import('../pages/products/category.vue' /* webpackChunkName: "pages/products/category" */))
const _b4ba1b24 = () => interopDefault(import('../pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _108f49da = () => interopDefault(import('../pages/auth/register/seller.vue' /* webpackChunkName: "pages/auth/register/seller" */))
const _3e4af246 = () => interopDefault(import('../pages/categories/roller-blinds.vue' /* webpackChunkName: "pages/categories/roller-blinds" */))
const _79683224 = () => interopDefault(import('../pages/categories/curtain.vue' /* webpackChunkName: "pages/categories/curtain" */))
const _1071db26 = () => interopDefault(import('../pages/checkout/order/created.vue' /* webpackChunkName: "pages/checkout/order/created" */))
const _6d4f5bad = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _418476dd = () => interopDefault(import('../pages/admin/bank-transfer.vue' /* webpackChunkName: "pages/admin/bank-transfer" */))
const _42515824 = () => interopDefault(import('../pages/admin/invoice.vue' /* webpackChunkName: "pages/admin/invoice" */))
const _52f1c287 = () => interopDefault(import('../pages/b2b/success.vue' /* webpackChunkName: "pages/b2b/success" */))
const _ad3b7682 = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _5d5c55d8 = () => interopDefault(import('../pages/profile/tradeCredit.vue' /* webpackChunkName: "pages/profile/tradeCredit" */))
const _979e4dae = () => interopDefault(import('../pages/profile/comments.vue' /* webpackChunkName: "pages/profile/comments" */))
const _5057a414 = () => interopDefault(import('../pages/messenger.vue' /* webpackChunkName: "pages/messenger" */))
const _1bd77bdd = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _be0bcd1a = () => interopDefault(import('../pages/profile/change-password.vue' /* webpackChunkName: "pages/profile/change-password" */))
const _3f93d183 = () => interopDefault(import('../pages/admin/pricesOnQuantity.vue' /* webpackChunkName: "pages/admin/pricesOnQuantity" */))
const _aa713722 = () => interopDefault(import('../pages/admin/offer/add.vue' /* webpackChunkName: "pages/admin/offer/add" */))
const _09e2447c = () => interopDefault(import('../pages/admin/product/add.vue' /* webpackChunkName: "pages/admin/product/add" */))
const _9bea56b6 = () => interopDefault(import('../pages/admin/sizes/add.vue' /* webpackChunkName: "pages/admin/sizes/add" */))
const _59ae77c7 = () => interopDefault(import('../pages/admin/store/edit.vue' /* webpackChunkName: "pages/admin/store/edit" */))
const _5679983d = () => interopDefault(import('../pages/admin/profile-edit.vue' /* webpackChunkName: "pages/admin/profile-edit" */))
const _438ec488 = () => interopDefault(import('../pages/admin/filters.vue' /* webpackChunkName: "pages/admin/filters" */))
const _4d42b991 = () => interopDefault(import('../pages/admin/clientGroups/index.vue' /* webpackChunkName: "pages/admin/clientGroups/index" */))
const _64cceee6 = () => interopDefault(import('../pages/admin/spreadsheet.vue' /* webpackChunkName: "pages/admin/spreadsheet" */))
const _5fcc6558 = () => interopDefault(import('../pages/admin/clients/list.vue' /* webpackChunkName: "pages/admin/clients/list" */))
const _aa50b402 = () => interopDefault(import('../pages/admin/tradeCredit/list.vue' /* webpackChunkName: "pages/admin/tradeCredit/list" */))
const _79d081f3 = () => interopDefault(import('../pages/admin/coupons&groupDeal.vue' /* webpackChunkName: "pages/admin/coupons&groupDeal" */))
const _5ad02d94 = () => interopDefault(import('../pages/admin/marketing/list.vue' /* webpackChunkName: "pages/admin/marketing/list" */))
const _1be1e9dc = () => interopDefault(import('../pages/admin/tools.vue' /* webpackChunkName: "pages/admin/tools" */))
const _4aa3a940 = () => interopDefault(import('../pages/admin/offer/list.vue' /* webpackChunkName: "pages/admin/offer/list" */))
const _828f5fb8 = () => interopDefault(import('../pages/admin/cms/page.vue' /* webpackChunkName: "pages/admin/cms/page" */))
const _932fa372 = () => interopDefault(import('../pages/admin/payment.vue' /* webpackChunkName: "pages/admin/payment" */))
const _8ec5d496 = () => interopDefault(import('../pages/admin/cms/post.vue' /* webpackChunkName: "pages/admin/cms/post" */))
const _bedf4292 = () => interopDefault(import('../pages/admin/employees/list.vue' /* webpackChunkName: "pages/admin/employees/list" */))
const _034b5a4d = () => interopDefault(import('../pages/admin/product/list.vue' /* webpackChunkName: "pages/admin/product/list" */))
const _2bce3fca = () => interopDefault(import('../pages/admin/sizes/list.vue' /* webpackChunkName: "pages/admin/sizes/list" */))
const _689f0fd1 = () => interopDefault(import('../pages/admin/descriptionTemplates/list.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/list" */))
const _01c5824d = () => interopDefault(import('../pages/admin/cms/updatePage.vue' /* webpackChunkName: "pages/admin/cms/updatePage" */))
const _08ab7044 = () => interopDefault(import('../pages/admin/cms/updatePost.vue' /* webpackChunkName: "pages/admin/cms/updatePost" */))
const _0afe3ad8 = () => interopDefault(import('../pages/admin/messenger.vue' /* webpackChunkName: "pages/admin/messenger" */))
const _1f1ce1bd = () => interopDefault(import('../pages/admin/shipping.vue' /* webpackChunkName: "pages/admin/shipping" */))
const _023874b7 = () => interopDefault(import('../pages/admin/orders/index.vue' /* webpackChunkName: "pages/admin/orders/index" */))
const _0fb93a59 = () => interopDefault(import('../pages/admin/change-password.vue' /* webpackChunkName: "pages/admin/change-password" */))
const _05f507c9 = () => interopDefault(import('../pages/auth/confirm-phone.vue' /* webpackChunkName: "pages/auth/confirm-phone" */))
const _c7d143c4 = () => interopDefault(import('../pages/admin/groupDeal/add.vue' /* webpackChunkName: "pages/admin/groupDeal/add" */))
const _74513994 = () => interopDefault(import('../pages/admin/clientGroups/manage.vue' /* webpackChunkName: "pages/admin/clientGroups/manage" */))
const _701f648e = () => interopDefault(import('../pages/admin/marketing/add.vue' /* webpackChunkName: "pages/admin/marketing/add" */))
const _f77ab7d0 = () => interopDefault(import('../pages/admin/employees/add.vue' /* webpackChunkName: "pages/admin/employees/add" */))
const _616c67be = () => interopDefault(import('../pages/admin/descriptionTemplates/add.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/add" */))
const _493e7446 = () => interopDefault(import('../pages/admin/orders/add.vue' /* webpackChunkName: "pages/admin/orders/add" */))
const _3c3fdebc = () => interopDefault(import('../pages/admin/marketing/edit.vue' /* webpackChunkName: "pages/admin/marketing/edit" */))
const _77e7373d = () => interopDefault(import('../pages/admin/descriptionTemplates/edit.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/edit" */))
const _7ca56212 = () => interopDefault(import('../pages/auth/confirm-email.vue' /* webpackChunkName: "pages/auth/confirm-email" */))
const _6fdcd185 = () => interopDefault(import('../pages/profile/orders/_id.vue' /* webpackChunkName: "pages/profile/orders/_id" */))
const _39936278 = () => interopDefault(import('../pages/admin/groupDeal/deals.vue' /* webpackChunkName: "pages/admin/groupDeal/deals" */))
const _dac9e646 = () => interopDefault(import('../pages/admin/groupDeal/edit.vue' /* webpackChunkName: "pages/admin/groupDeal/edit" */))
const _70abcdfc = () => interopDefault(import('../pages/admin/clients/details.vue' /* webpackChunkName: "pages/admin/clients/details" */))
const _b9669d1e = () => interopDefault(import('../pages/admin/tradeCredit/details.vue' /* webpackChunkName: "pages/admin/tradeCredit/details" */))
const _59ebd0ac = () => interopDefault(import('../pages/admin/offer/edit.vue' /* webpackChunkName: "pages/admin/offer/edit" */))
const _a04ef3ba = () => interopDefault(import('../pages/admin/employees/edit.vue' /* webpackChunkName: "pages/admin/employees/edit" */))
const _129381b9 = () => interopDefault(import('../pages/admin/product/edit.vue' /* webpackChunkName: "pages/admin/product/edit" */))
const _3b166736 = () => interopDefault(import('../pages/admin/sizes/edit.vue' /* webpackChunkName: "pages/admin/sizes/edit" */))
const _300b8e42 = () => interopDefault(import('../pages/admin/orders/_id.vue' /* webpackChunkName: "pages/admin/orders/_id" */))
const _d55406e8 = () => interopDefault(import('../pages/groupDeal.vue' /* webpackChunkName: "pages/groupDeal" */))
const _a3d0d83e = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _ac19125a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _48ce1638 = () => interopDefault(import('../pages/cms/postPage.vue' /* webpackChunkName: "pages/cms/postPage" */))
const _629d02aa = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link',
  linkExactActiveClass: 'is-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _0e1b0b0e,
    props: false,
    name: "404___pl"
  }, {
    path: "/b2b",
    component: _745416d6,
    props: false,
    name: "b2b___pl"
  }, {
    path: "/blog",
    component: _7f9a6a37,
    props: false,
    name: "cms-blog___pl"
  }, {
    path: "/curtains",
    component: _ac46d016,
    props: false,
    name: "categories-curtains___pl"
  }, {
    path: "/doplata",
    component: _47fd6d85,
    props: false,
    name: "surcharge___pl"
  }, {
    path: "/fitted-sheets",
    component: _2fca04ec,
    props: false,
    name: "categories-relax___pl"
  }, {
    path: "/kasa",
    component: _7b486698,
    props: false,
    name: "checkout___pl"
  }, {
    path: "/logowanie",
    component: _fba69e58,
    props: false,
    name: "auth-login___pl"
  }, {
    path: "/logowanie-sprzedawca",
    component: _a7332d26,
    props: false,
    name: "auth-login-seller___pl"
  }, {
    path: "/montaz-plisy-wideo-instrukcja",
    component: _accf6876,
    props: false,
    name: "static-montage___pl"
  }, {
    path: "/nadruk",
    component: _6ef99b2e,
    props: false,
    name: "print___pl"
  }, {
    path: "/panel-sprzedawcy",
    component: _037ff553,
    props: false,
    name: "admin___pl"
  }, {
    path: "/pleated-blinds",
    component: _5ea4d0b8,
    props: false,
    name: "categories-pleated-blinds___pl"
  }, {
    path: "/product",
    component: _de2d63a0,
    props: false,
    children: [{
      path: "/:productName-p:productId(\\d+)",
      component: _b9a31e9a,
      name: "product___pl"
    }]
  }, {
    path: "/products",
    component: _e027ce1a,
    props: false,
    children: [{
      path: "/produkty",
      component: _625e4594,
      name: "products___pl"
    }, {
      path: "/:categoryName-c:categoryId(\\d+)",
      component: _6c5d824a,
      name: "products-category___pl"
    }]
  }, {
    path: "/rejestracja",
    component: _b4ba1b24,
    props: false,
    name: "auth-register___pl"
  }, {
    path: "/rejestracja-sprzedawca",
    component: _108f49da,
    props: false,
    name: "auth-register-seller___pl"
  }, {
    path: "/roller-blinds",
    component: _3e4af246,
    props: false,
    name: "categories-roller-blinds___pl"
  }, {
    path: "/vorhang",
    component: _79683224,
    props: false,
    name: "categories-curtain___pl"
  }, {
    path: "/zamowienie-utworzone",
    component: _1071db26,
    props: false,
    name: "checkout-order-created___pl"
  }, {
    path: "/zapomnialem-hasla",
    component: _6d4f5bad,
    props: false,
    name: "auth-forgot-password___pl"
  }, {
    path: "/admin/bank-transfer",
    component: _418476dd,
    props: false,
    name: "admin-bank-transfer___pl"
  }, {
    path: "/admin/invoice",
    component: _42515824,
    props: false,
    name: "admin-invoice___pl"
  }, {
    path: "/b2b/success",
    component: _52f1c287,
    props: false,
    name: "b2b-success___pl"
  }, {
    path: "/moje-konto/edytuj",
    component: _ad3b7682,
    props: false,
    name: "profile-edit___pl"
  }, {
    path: "/moje-konto/kredyt-kupiecki",
    component: _5d5c55d8,
    props: false,
    name: "profile-tradeCredit___pl"
  }, {
    path: "/moje-konto/opinie",
    component: _979e4dae,
    props: false,
    name: "profile-comments___pl"
  }, {
    path: "/moje-konto/wiadomosci",
    component: _5057a414,
    props: false,
    name: "messenger___pl"
  }, {
    path: "/moje-konto/zamowienia",
    component: _1bd77bdd,
    props: false,
    name: "profile-orders___pl"
  }, {
    path: "/moje-konto/zmiana-hasla",
    component: _be0bcd1a,
    props: false,
    name: "profile-change-password___pl"
  }, {
    path: "/panel-sprzedawcy/ceny-od-ilosci",
    component: _3f93d183,
    props: false,
    name: "admin-pricesOnQuantity___pl"
  }, {
    path: "/panel-sprzedawcy/dodaj-oferte",
    component: _aa713722,
    props: false,
    name: "admin-offer-add___pl"
  }, {
    path: "/panel-sprzedawcy/dodaj-produkt",
    component: _09e2447c,
    props: false,
    name: "admin-product-add___pl"
  }, {
    path: "/panel-sprzedawcy/dodaj-rozmiar",
    component: _9bea56b6,
    props: false,
    name: "admin-sizes-add___pl"
  }, {
    path: "/panel-sprzedawcy/edycja-sklepu",
    component: _59ae77c7,
    props: false,
    name: "admin-store-edit___pl"
  }, {
    path: "/panel-sprzedawcy/edytuj",
    component: _5679983d,
    props: false,
    name: "admin-profile-edit___pl"
  }, {
    path: "/panel-sprzedawcy/filtry",
    component: _438ec488,
    props: false,
    name: "admin-filters___pl"
  }, {
    path: "/panel-sprzedawcy/grupy-klientow",
    component: _4d42b991,
    props: false,
    name: "admin-clientGroups___pl"
  }, {
    path: "/panel-sprzedawcy/import-export",
    component: _64cceee6,
    props: false,
    name: "admin-spreadsheet___pl"
  }, {
    path: "/panel-sprzedawcy/klienci",
    component: _5fcc6558,
    props: false,
    name: "admin-clients-list___pl"
  }, {
    path: "/panel-sprzedawcy/kredyt-kupiecki",
    component: _aa50b402,
    props: false,
    name: "admin-tradeCredit-list___pl"
  }, {
    path: "/panel-sprzedawcy/kupony-deals",
    component: _79d081f3,
    props: false,
    name: "admin-coupons&groupDeal___pl"
  }, {
    path: "/panel-sprzedawcy/marketing",
    component: _5ad02d94,
    props: false,
    name: "admin-marketing-list___pl"
  }, {
    path: "/panel-sprzedawcy/narzedzia",
    component: _1be1e9dc,
    props: false,
    name: "admin-tools___pl"
  }, {
    path: "/panel-sprzedawcy/oferty",
    component: _4aa3a940,
    props: false,
    name: "admin-offer-list___pl"
  }, {
    path: "/panel-sprzedawcy/page",
    component: _828f5fb8,
    props: false,
    name: "admin-cms-page___pl"
  }, {
    path: "/panel-sprzedawcy/platnosci",
    component: _932fa372,
    props: false,
    name: "admin-payment___pl"
  }, {
    path: "/panel-sprzedawcy/post",
    component: _8ec5d496,
    props: false,
    name: "admin-cms-post___pl"
  }, {
    path: "/panel-sprzedawcy/pracownicy",
    component: _bedf4292,
    props: false,
    name: "admin-employees-list___pl"
  }, {
    path: "/panel-sprzedawcy/produkty",
    component: _034b5a4d,
    props: false,
    name: "admin-product-list___pl"
  }, {
    path: "/panel-sprzedawcy/rozmiary",
    component: _2bce3fca,
    props: false,
    name: "admin-sizes-list___pl"
  }, {
    path: "/panel-sprzedawcy/szablony-opisow",
    component: _689f0fd1,
    props: false,
    name: "admin-descriptionTemplates-list___pl"
  }, {
    path: "/panel-sprzedawcy/updatePage",
    component: _01c5824d,
    props: false,
    name: "admin-cms-updatePage___pl"
  }, {
    path: "/panel-sprzedawcy/updatePost",
    component: _08ab7044,
    props: false,
    name: "admin-cms-updatePost___pl"
  }, {
    path: "/panel-sprzedawcy/wiadomosci",
    component: _0afe3ad8,
    props: false,
    name: "admin-messenger___pl"
  }, {
    path: "/panel-sprzedawcy/wysylki",
    component: _1f1ce1bd,
    props: false,
    name: "admin-shipping___pl"
  }, {
    path: "/panel-sprzedawcy/zamowienia",
    component: _023874b7,
    props: false,
    name: "admin-orders___pl"
  }, {
    path: "/panel-sprzedawcy/zmiana-hasla",
    component: _0fb93a59,
    props: false,
    name: "admin-change-password___pl"
  }, {
    path: "/telefon/potwierdzenie",
    component: _05f507c9,
    props: false,
    name: "auth-confirm-phone___pl"
  }, {
    path: "/panel-sprzedawcy/groupDeal/dodaj",
    component: _c7d143c4,
    props: false,
    name: "admin-groupDeal-add___pl"
  }, {
    path: "/panel-sprzedawcy/grupy-klientow/zarzadzaj",
    component: _74513994,
    props: false,
    name: "admin-clientGroups-manage___pl"
  }, {
    path: "/panel-sprzedawcy/marketing/dodaj",
    component: _701f648e,
    props: false,
    name: "admin-marketing-add___pl"
  }, {
    path: "/panel-sprzedawcy/pracownicy/dodaj",
    component: _f77ab7d0,
    props: false,
    name: "admin-employees-add___pl"
  }, {
    path: "/panel-sprzedawcy/szablony-opisow/dodaj",
    component: _616c67be,
    props: false,
    name: "admin-descriptionTemplates-add___pl"
  }, {
    path: "/panel-sprzedawcy/zamowienia/dodaj",
    component: _493e7446,
    props: false,
    name: "admin-orders-add___pl"
  }, {
    path: "/panel-sprzedawcy/marketing/edycja/:id",
    component: _3c3fdebc,
    props: false,
    name: "admin-marketing-edit___pl"
  }, {
    path: "/panel-sprzedawcy/szablony-opisow/edycja/:id",
    component: _77e7373d,
    props: false,
    name: "admin-descriptionTemplates-edit___pl"
  }, {
    path: "/email/potwierdzenie/:token",
    component: _7ca56212,
    props: false,
    name: "auth-confirm-email___pl"
  }, {
    path: "/moje-konto/zamowienie/:id",
    component: _6fdcd185,
    props: true,
    name: "profile-orders-id___pl"
  }, {
    path: "/panel-sprzedawcy/deals/:groupDeal",
    component: _39936278,
    props: false,
    name: "admin-groupDeal-deals___pl"
  }, {
    path: "/panel-sprzedawcy/groupDeal/:id",
    component: _dac9e646,
    props: false,
    name: "admin-groupDeal-edit___pl"
  }, {
    path: "/panel-sprzedawcy/klient/:id",
    component: _70abcdfc,
    props: false,
    name: "admin-clients-details___pl"
  }, {
    path: "/panel-sprzedawcy/kredyt-kupiecki/:id",
    component: _b9669d1e,
    props: false,
    name: "admin-tradeCredit-details___pl"
  }, {
    path: "/panel-sprzedawcy/oferta/:id",
    component: _59ebd0ac,
    props: false,
    name: "admin-offer-edit___pl"
  }, {
    path: "/panel-sprzedawcy/pracownicy/:id",
    component: _a04ef3ba,
    props: false,
    name: "admin-employees-edit___pl"
  }, {
    path: "/panel-sprzedawcy/produkt/:id",
    component: _129381b9,
    props: false,
    name: "admin-product-edit___pl"
  }, {
    path: "/panel-sprzedawcy/rozmiar/:id",
    component: _3b166736,
    props: false,
    name: "admin-sizes-edit___pl"
  }, {
    path: "/panel-sprzedawcy/zamowienie/:id",
    component: _300b8e42,
    props: true,
    name: "admin-orders-id___pl"
  }, {
    path: "/groupDeal/:identify",
    component: _d55406e8,
    props: false,
    name: "groupDeal___pl"
  }, {
    path: "/reset-hasla/:token",
    component: _a3d0d83e,
    props: false,
    name: "auth-reset-password___pl"
  }, {
    path: "/",
    component: _ac19125a,
    props: false,
    name: "index___pl"
  }, {
    path: "/:slug",
    component: _48ce1638,
    props: false,
    name: "cms-postPage___pl"
  }, {
    path: "/*",
    component: _629d02aa,
    props: false,
    name: "*___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
